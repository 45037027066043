var $studyProgram = $('.study-program');

$studyProgram.find('a.read-more').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    var $program = $this.parents('.program');

    $this.find('img').toggleClass('open');

    $studyProgram.find('a.signup').find('img').removeClass('open');

    $program.find('.extended-info').slideToggle();
    $program.find('.signup-form').slideUp();

});

$studyProgram.find('a.signup').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    var $program = $this.parents('.program');

    var $readMore = $program.find('a.read-more');
    $readMore.find('img').removeClass('open');

    $this.find('img').toggleClass('open');

    $program.find('.extended-info').slideUp();
    $program.find('.signup-form').slideToggle();

});

