var $header = $('header');
var $nav = $('nav');
var $navIcon = $('#nav-icon');

$navIcon.click(function(e) {
    e.preventDefault();

    var isOpen = $header.hasClass('mobile-open');

    if (isOpen) {
        closeMobileMenu();
    } else {
        openMobileMenu();
    }
});

function openMobileMenu() {
    $header.addClass('mobile-open');
    setTimeout(function() {
        $nav.addClass('show');
    }, 20);

    $navIcon.addClass('open');
}

function closeMobileMenu() {
    $nav.removeClass('show');
    setTimeout(function() {
        $header.removeClass('mobile-open');
    }, 300);
    
    $navIcon.removeClass('open');
}

$('nav a').click(function() {
    if ($(this).attr('href').indexOf('#') === -1) {
        return;
    }

    var isOpen = $header.hasClass('mobile-open');
    if (isOpen) {
        closeMobileMenu();
    }
});